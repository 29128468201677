import { CaseStatus } from '@/graphql/generated/schemas'

export const caseStatusOptions = [
  { value: CaseStatus.Active, label: 'Active', color: '#931200' },
  { value: CaseStatus.Closed, label: 'Closed', color: '#29D11E' },
]

export const caseUpdateStatusOptions = [
  { value: CaseStatus.Active, label: 'Active' },
  { value: CaseStatus.Closed, label: 'Closed' },
]
